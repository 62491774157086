.Switch {
    padding: 5px;
    cursor: pointer;
    transition: 0.4s;
    border-radius: 999px;
}
.Inner {
    aspect-ratio: 1/1;
    border-radius: 99px;
    background-color: #fff;
}