.Content {
    width: 35%;
}
.Container {
    position: absolute;
    top: 0px;left: 0px;right: 0px;bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Header {
    position: absolute;
    top: 0px;left: 0px;right: 0px;bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    padding: 0px 20px;
    z-index: 5;
}
.Header a {
    text-decoration: none;
    font-weight: bold;
    color: #0870cd;
}
.errorMessage {
    background-color: #e74c3c25;
    color: #e74c3c;
    padding: 10px;
    font-size: 12px;
    border-radius: 8px;
}

@media (max-width: 480px) {
    .Content {
        width: 90%;
    }
}