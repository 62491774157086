.Left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
}
.Title {
    font-size: 20px;
}
.Description {
    font-size: 14px;
    color: #666;
}