.Title {
    font-size: 32px;
    font-weight: 600;
    color: #222;
}
.Description {
    font-size: 16px;
    font-weight: 400;
    color: #717171;
    margin-top: 20px;
    line-height: 24px;
}

.LabelTitle {
    font-size: 18px;
    font-weight: 600;
    color: #222;
    margin-top: 20px;
}
.LabelDescription {
    font-size: 16px;
    font-weight: 400;
    color: #717171;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Container {
    padding: 40px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Content {
    width: 50%;
}

.UploadedPhoto {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 12px;
    object-fit: cover;
}

.CardArea {
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.Card {
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 12px;
    min-height: 100px;
    font-size: 18px;
}
.Card.Active {
    border: 2px solid #0870cd;
    background-color: #0870cd30;
}
.Card.Facility {
    align-items: flex-start;
    gap: 10px;
}

@media (max-width: 480px) {
    .Content {
        width: 90%;
    }
    .CardArea {
        flex-direction: column;
        gap: 20px;
    }
}