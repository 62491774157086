.Container {
    display: flex;
    flex-direction: row;
    position: relative;
}
.Area {
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px dotted #ddd;
    cursor: pointer;
    gap: 10px;
}
.Input {
    position: absolute;
    top: 0px;left: 0px;right: 0px;bottom: 0px;
    cursor: pointer;
    opacity: 0.01;
}