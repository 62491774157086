@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600;700;800;900&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
div {
  box-sizing: border-box;
}
/* div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
} */
section {
  padding: 50px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
pre {
  font-size: 16px;
  font-family: 'Roboto';
  line-height: 24px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.input {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  outline: none;
  font-size: 16px;
  color: #222;
  margin-top: 10px;
  font-family: "Roboto";
  box-sizing: border-box;
}
div.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 20px;
  flex-wrap: nowrap;
}
select.input {
  outline: 1px solid #ddd;
  padding: 0px 20px;
  border: none;
  border-right: 18px solid transparent;
}
textarea.input {
  height: 200px;
}

.relative { position: relative; }
.absolute { position: absolute; }
.content {
  position: absolute;
  top: 80px;left: 0px;right: 0px;
  z-index: 1;
}
.inner_content {
  padding: 40px;
}
.content.user {
  left: 20%;right: 20px;
  padding: 20px;
}
.content.organizer {
  left: 25%;right: 0px;
  top: 75px;
  padding: 20px;
}
.inline {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.title {
  font-weight: 600;
}
table {
  width: 100%;
  text-align: left;
  position: relative;
  text-align: left;
}
table tr th {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
table td {
  padding: 10px;
  text-align: left !important;
}
table tr:hover,
table tr:focus {
  background-color: var(--grey);
}
table tr:hover td:nth-child(1),
table tr:focus td:nth-child(1) {
  border-left: 5px solid var(--blue);
}

table tbody tr ul {
  display: none;
}
table tbody a {
  color: #444;
  text-decoration: none;
}
table tbody tr td span:hover ~ ul,
table tbody tr td ul:hover {
  display: block;
  position: absolute;
  right: 0px;
  margin-top: -5%;
  z-index: 2;
  background-color: #fff;
  border-radius: 6px;
  margin-left: -40%;
  border: 1px solid #ddd;
  min-width: 100px;
  padding-left: 0px;
  padding: 10px 0px;
}
table tbody tr td ul li {
  padding: 0px 15px;
}
table tbody tr td ul li:hover { background-color: var(--grey); }
table tbody tr td:last-child li {
  list-style: none;
  line-height: 35px;
  text-align: left;
}
/* table tbody tr td:last-child { text-align: right !important; } */
select {
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0px 20px;
}

@media (max-width: 480px) {
  section {
    padding: 20px;
  }
  .content {
    top: 80px;
    /* padding-bottom: 100px !important; */
  }
  .content.user {
    left: 20px;right: 20px;
    padding: 0px;
  }
  .content.organizer {
    top: 130px;left: 0px;right: 0px;
    z-index: 2;
  }
  .inner_content {
    padding: 20px;
  }
}