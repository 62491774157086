.Container {
    position: fixed;
    bottom: 0px;left: 0px;right: 0px;
    background-color: #fff;
}
.Step {
    display: flex;
    flex-grow: 1;
    background-color: #ddd;
    height: 4px;
}
.Step.Active {
    background-color: #0870cd;
}
.Content {
    padding: 0px 100px;
    height: 80px;
}
.Back {
    color: #222;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}
.Center {
    display: flex;
    flex-grow: 1;
}

@media (max-width: 480px) {
    .Content {
        padding: 0px 20px;
    }
}